import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-loading-skeleton/dist/skeleton.css';

const main = async () => {
    // lazy imports here let the app's core components be modularised, and config to be present when needed
    const { initAsync } = await import('./Config');
    await initAsync();

    const { default: App } = await import('./App');

    const { render } = await import('react-dom');

    render(
        <App />,
        document.getElementById('root')
    );
};

// eslint-disable-next-line no-console
main().catch(e => console.error(e));
